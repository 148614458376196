<template>
  <div>
    <vx-card>
      <div>
        <h2 class="mb-5">User Subscription Details</h2>

        <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12" class="user-detail-wrap">
          <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
            <div class="w-full p-5">
              <label class="font-semibold">Customer Name</label>
              <p>{{userSubscriptionData.userDetails.fullName | capitalize }}</p>
            </div>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
            <div class="w-full p-5">
              <label class="font-semibold">Company Name</label>
              <p>{{ userSubscriptionData.userDetails.companyName }}</p>
            </div>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
            <div class="w-full p-5">
              <label class="font-semibold">Email</label>
              <p>{{ userSubscriptionData.userDetails.email }}</p>
            </div>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
            <div class="w-full p-5">
              <label class="font-semibold">Contact Number</label>
              <p>{{ userSubscriptionData.userDetails.mobileNumber }}</p>
            </div>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
            <div class="w-full p-5">
              <label class="font-semibold">Account Status</label>
              <p>{{ userSubscriptionData.userDetails.accountStatus }}</p>
            </div>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
            <div class="w-full p-5">
              <label class="font-semibold">Address</label>
              <p>{{ userSubscriptionData.userDetails.address.state }}</p>
            </div>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
            <div class="w-full p-5">
              <label class="font-semibold">Package</label>
              <p>{{ userSubscriptionData.subscriptionDetails.type }}</p>
            </div>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
            <div class="w-full p-5">
              <label class="font-semibold">Package Status</label>
              <p>{{ moment(userSubscriptionData.paymentQue.endDate) >= moment() ? "Active" : "Expired" }}</p>
            </div>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
            <div class="w-full p-5">
              <label class="font-semibold">Price</label>
              <p>${{ userSubscriptionData.details.price }}/per child</p>
            </div>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
            <div class="w-full p-5">
              <label class="font-semibold">Purchased Date</label>
              <p>{{ moment(userSubscriptionData.details.purchasedAt).format('DD/MM/YYYY') }}</p>
            </div>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
            <div class="w-full p-5">
              <label class="font-semibold">Start Date</label>
              <p>{{ moment(userSubscriptionData.paymentQue.startDate).format('DD/MM/YYYY') }}</p>
            </div>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
            <div class="w-full p-5">
              <label class="font-semibold">End Date</label>
              <p>{{ (userSubscriptionData.paymentQue.endDate)?moment(userSubscriptionData.paymentQue.endDate).format('DD/MM/YYYY'):"-" }}</p>
            </div>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
            <div class="w-full p-5">
              <label class="font-semibold">Billing Started At</label>
              <p>{{ moment(userSubscriptionData.details.billingStartedAt).format('DD/MM/YYYY') }}</p>
            </div>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
            <div class="w-full p-5">
              <label class="font-semibold">Original End Date</label>
              <p>{{ (userSubscriptionData.details.originalEndDate)?moment(userSubscriptionData.details.originalEndDate).format('DD/MM/YYYY'):"-" }}</p>
            </div>
          </vs-col>

          <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
            <div class="w-full p-5">
              <label class="font-semibold">Cancellation Date</label>
              <p>{{ (userSubscriptionData.details.candelledAt)?moment(userSubscriptionData.details.candelledAt).format('DD/MM/YYYY'):"-" }}</p>
            </div>
          </vs-col>

          <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
            <div class="w-full p-5">
              <label class="font-semibold">Is it Auto Renewal?</label>
              <p>{{ userSubscriptionData.details.isAutoRenewal ? "Yes" : "No" }}</p>
            </div>
          </vs-col>
        </vs-row>
      </div>
    </vx-card>
  </div>
</template>

<script>
  import { mapActions } from "vuex";
  import moment from "moment";

  export default {
    data() {
      return {
        userSubscriptionData: {},
      }
    },
    methods: {
      ...mapActions("userSubscription", [
        "getUserSubscriptionsDetails",
      ]),
      moment(date){
        return moment(date)
      },
      getUserSubscriptionsDetail(id) {
        this.getUserSubscriptionsDetails(id).then(res => {
          this.userSubscriptionData = res.data.data;
        });
      },
    },
    created() {
      let userSubscriptionsId = this.$route.params.id;
      this.getUserSubscriptionsDetail(userSubscriptionsId);
    }
  }
</script>
<style lang="scss">
  .vs-card--content {
    font-size: 16px;
    line-height: 25px;
    label {
      font-weight: 600;
    }
  }

  .user-detail-wrap {
    border: 1px solid #e7e7e7;
    .w-full {
      label {
        position: relative;
        margin-right: 20px;
        &:after {
          content: ':';
          font-weight: 700;
          position: absolute;
          top: -1px;
          right: 0;
        }
      }
      @media (min-width: 768px){
        display: flex;
        label {
          width: 250px;
        }
      }
    }
  }
</style>
